import React from "react";
import { transformVar } from "@quarkly/atomize";
import { createGlobalStyle } from "styled-components";

const pageStyles = {
    "404": {},
    "index": {
        "overflow-x": "hidden"
    },
    "about-us": {
        "background": "#EEEEEE"
    },
    "team": {},
    "optical-device-fabrication": {},
    "data-storage-innovation": {},
    "memory-crystal": {},
    "contact": {
        "background": "#EEEEEE"
    },
    "brief-history": {
        "background": "#EEEEEE"
    },
    "coming-soon": {
        "background": "#EEEEEE"
    },
    "blog": {
        "background": "#EEEEEE"
    },
    "recognition": {
        "background": "#EEEEEE"
    },
    "facilities": {
        "background": "#EEEEEE"
    },
    "__form_test__": {},
    "privacy-policy": {
        "background": "#EEEEEE"
    },
    "newspage": {
        "background": "#FFF"
    },
    "datacenters3pct": {
        "background": "#FFF"
    },
    "spie2025": {
        "background": "#FFF"
    },
    "adipec2024": {
        "background": "#FFF"
    },
    "boucheron": {
        "background": "#FFF"
    }
};

const PageStyles = createGlobalStyle`
    body {
        ${({ styles }) =>
            Object.entries(styles || {}).map(
                ([prop, value]) => `${prop}: ${transformVar(prop, value)};`
            )}
    }
`;
export const GlobalQuarklyPageStyles = ({ pageUrl }) => <PageStyles styles={pageStyles[pageUrl]} />
